import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { useTabs } from './context'
import useCallbackRef from '../hooks/useCallbackRef'
import { useConfig } from '../ConfigProvider'
import PropTypes from 'prop-types'
import { isDesktop } from 'react-device-detect'

const TabNav = forwardRef((props, ref) => {
    const {
        value: valueProp,
        disabled,
        className,
        icon,
        children,
        ...rest
    } = props

    const { value, onValueChange, variant } = useTabs()
    const isSelected = valueProp === value

    const { themeColor, primaryColorLevel } = useConfig()

    const onTabNavClick = useCallbackRef(() => {
        if (!isSelected && !disabled) {
            onValueChange(valueProp)
        }
    })

    const color = `${themeColor}-${primaryColorLevel}`

    const tabNavClass = classNames(
        isDesktop ?'tab-nav': "text-sm",
        `tab-nav-${variant} `,
        isSelected && `tab-nav-active text-white `,
        isSelected && variant === 'underline' && ``,
        isSelected &&
        variant === 'pill' &&
        `bg-gradient-to-t from-orange-200 to-orange-100 rounded-none last:rounded-tr-lg font-semibold text-white`,
        disabled && 'tab-nav-disabled',
        !isSelected &&
        variant === 'pill' &&
        `text-gray-75 bg-gradient-to-t to-[#F2F2F2] from-[#E0E0E0] last:rounded-tr-lg font-semibold  `,
        !disabled &&
        !isSelected &&
        variant === 'pill' &&
        `hover:text-orange-100 rounded-none transition-all duration-300`,

        isSelected &&
        variant === 'pill-two' &&
        `bg-gradient-to-t from-orange-200  to-orange-100  font-semibold  text-white " `,
        !isSelected &&
        variant === 'pill-two' &&
        ` bg-orange-50  font-semibold text-orange-200 `,
        className,

        isSelected &&
        variant === 'auth' && 
        `border-b-4 border-orange-200 text-orange-200 ${isDesktop ? "h-14" : "h-12"}`,
        !isSelected && 
        variant === 'auth' &&
        `text-gray-25 ${isDesktop ? "h-14" : "h-12"}`,

        isSelected &&
        variant === 'forgot-pwd' &&
        `border-b-2 border-orange-200 text-orange-200 font-semibold`,
        !isSelected &&
        variant === 'forgot-pwd' &&
        `border-b border-gray-25`,

        isSelected &&
        variant === 'my-parcel' &&
        `border-b-4 border-orange-200 text-orange-200 fade-in`,
        !isSelected &&
        variant === 'my-parcel' &&
        `text-gray-700`,

        isSelected &&
        variant === 'pricing' &&
        `text-white bg-gradient-to-t from-orange-200 to-orange-100`,
        !isSelected &&
        variant === 'pricing' &&
        `text-gray-700 bg-gradient-to-t from-[#E1E1E1] to-[#F1F1F1] hover:text-orange-100 transition-all duration-300`,

        isSelected &&
        variant === 'pricing-mobile' &&
        `text-white bg-gradient-to-t from-orange-200 to-orange-100`,
        !isSelected &&
        variant === 'pricing-mobile' &&
        `text-gray-700 bg-gradient-to-t from-[#E1E1E1] to-[#F1F1F1]`
    )

    return (
        <div
            className={tabNavClass}
            role="tab"
            aria-selected={isSelected}
            ref={ref}
            onClick={onTabNavClick}
            {...rest}
        >
            {icon && <div className="tab-nav-icon">{icon}</div>}
            {children}
        </div>
    )
})

TabNav.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default TabNav
