import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    // avatar: '',
    // userName: '',
    // email: '',
    // authority: [],

    username: "",
    group_id: '',
    group_level: '',
    country_id: 1,
    cus_id: '',
    country_code: 'MY',
    timezone: 'Asia/Kuala_Lumpur'
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
