import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, REQUEST_HEADER_LANG } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

const BaseService = axios.create({
    // timeout: 60000,
    baseURL: (process.env.REACT_APP_DEVELOPMENT === "true" ? process.env.REACT_APP_API_DEVELOPMENT_URL + 'api/member/' : appConfig.apiPrefix)  //development
    // baseURL: appConfig.apiPrefix, 
})

BaseService.defaults.headers.common['X-Authorization'] = process.env.REACT_APP_X_AUTHORIZATION_KEY;

if (localStorage.getItem('accessToken')) {
    BaseService.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
}

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        const accessToken = persistData.auth.session.token
        const lang = persistData.locale.currentLang

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }
        config.headers[REQUEST_HEADER_LANG] = lang

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (error.response &&
            (error.response.data.message[0] === "Token is Incompatible" ||
                error.response.data.message[0] === "Token is Expired" ||
                error.response.data.message[0] === "Token is Invalid")) {
            localStorage.clear();
            window.location.href = "/";
        }

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService
