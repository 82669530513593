import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: (process.env.REACT_APP_DEVELOPMENT === "true" ? '/login' : '/sign-in'),
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/register',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function apiGetCountry(data){
    return ApiService.fetchData({
        url: '/country',
        method: 'get',
        data
    })
}

export async function apiGenerateRegisterOtp(data){
    return ApiService.fetchData({
        url: '/generate_register_otp',
        method: 'post',
        data
    })
}

export async function apiResendRegisterOtp(data){
    return ApiService.fetchData({
        url: '/resend_register_otp',
        method: 'post',
        data
    })
}
