import React from 'react'
import PropTypes from 'prop-types'
import {
    HiCheckCircle,
    HiInformationCircle,
    HiExclamation,
} from 'react-icons/hi'
import { 
    IoCloseCircleSharp, 
    IoCheckmarkCircleSharp 
} from "react-icons/io5";

const ICONS = {
    success: {
        color: 'text-green-400',
        icon: <IoCheckmarkCircleSharp />,
    },
    info: {
        color: 'text-blue-400',
        icon: <HiInformationCircle />,
    },
    warning: {
        color: 'text-yellow-400',
        icon: <HiExclamation />,
    },
    danger: {
        color: 'text-red-200',
        icon: <IoCloseCircleSharp />,
    },
}

const StatusIcon = (props) => {
    const { type, custom, iconColor } = props

    const icon = ICONS[type]

    return (
        <span className={`text-6xl ${iconColor || icon.color}`}>
            {custom || icon.icon}
        </span>
    )
}

StatusIcon.defaultProps = {
    type: 'info',
}

StatusIcon.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
    custom: PropTypes.node,
}

export default StatusIcon
