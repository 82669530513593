import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './en/translation.json';
import translationZHCN from './cn/translation.json';
import translationMS from './ms/translation.json';
import translationIndo from './indon/translation.json';

let initLang = localStorage.getItem('lang') || "en";
// if(!localStorage.getItem("accessToken")){
//     initLang = (localStorage.getItem("lang")) ? localStorage.getItem("lang") : 'en';
// }

i18n
  .use(initReactI18next)
  .init({
    resources: {
        en: {
            translation: translationEN,
            // games: gamesEN,
            // routes: routesEN,
        },
        zh_cn: {
            translation: translationZHCN,
            // games: gamesZHCN,
            // routes: routesZHCN,
        },
        ms: {
            translation: translationMS,
            // games: gamesMS,
            // routes: routesMS,
        },
        id: {
            translation: translationIndo
        }
    },
    lng: initLang, // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
        escapeValue: false // React already safes from xss
    },
    react: {
        wait: false
    }
});

export default i18n;
